import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { containerCSS } from "@/game-lol/components/MatchBans.style.jsx";
import { TEAMS } from "@/game-lol/constants/constants.mjs";
import Static from "@/game-lol/utils/static.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const MatchBans = ({ region, matchId }) => {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const match = state.lol.matches[`${region?.toUpperCase()}_${matchId}`];

  if (!match) return null;

  const team1Bans = match?.teams?.[0]?.bans || [];
  const team2Bans = match?.teams?.[1]?.bans || [];

  if (![...team1Bans, ...team2Bans].length) return null;

  return (
    <div className={containerCSS()}>
      <ol className="team-bans" style={{ "--team-color": TEAMS.BLUE.color }}>
        {team1Bans.map((ban, i) => {
          return (
            <li key={i} className="team-ban">
              <img
                src={Static.getChampionImage(ban.championId)}
                width="28"
                height="28"
                loading="lazy"
              />
            </li>
          );
        })}
      </ol>
      <span className="type-caption">{t("lol:bans", "BANS")}</span>
      <ol className="team-bans" style={{ "--team-color": TEAMS.RED.color }}>
        {team2Bans.map((ban, i) => {
          return (
            <li key={i} className="team-ban">
              <img
                src={Static.getChampionImage(ban.championId)}
                width="28"
                height="28"
                loading="lazy"
              />
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default MatchBans;
