import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/constants.mjs";
import MatchPerformance from "@/game-lol/components/MatchPerformance.jsx";
import MatchTabs from "@/game-lol/components/MatchTabs.jsx";
import SupportTimer from "@/game-lol/components/PostMatchSupportTimer.jsx";
import { DEFAULT_COMPARISON_RANK } from "@/game-lol/constants/coaching-constants.mjs";
import {
  QUEUE_SYMBOLS,
  REGION_LIST,
  ROLE_SYMBOLS,
  SCORABLE_QUEUE_TYPES,
} from "@/game-lol/constants/constants.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import { isSRCoachableQueue } from "@/game-lol/utils/coaching-utils.mjs";
import isLinkedSummoner from "@/game-lol/utils/is-linked-summoner.mjs";
import PlayerScores from "@/game-lol/utils/player-scores.mjs";
import QueueSymbol from "@/game-lol/utils/symbol-queue.mjs";
import {
  useGetPostmatchData,
  useGetTagsData,
} from "@/game-lol/utils/use-postmatch-data.mjs";
import {
  getDerivedId,
  getLPColor,
  getLPString,
  getStaticData,
  getTranslatedQueueName,
  isARAMQueue,
  isArenaQueue,
  isRankedQueue,
  isSameAccount,
  isSwarmQueue,
  mapRoleToSymbol,
  separateDerivedRiotID,
} from "@/game-lol/utils/util.mjs";
import { useReRender } from "@/shared/EventedRender";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import matchRefs from "@/shared/Match.refs.jsx";
import { MainColumnsContainer } from "@/shared/Match.style.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { MatchContextProvider } from "@/shared/use-match-context.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import unionBy from "@/util/union-by.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const CUSTOM_QUEUE_COMPONENTS = {
  [QUEUE_SYMBOLS.swarm]: () => import("@/game-lol/components/SwarmScore.jsx"),
};

function PostMatch() {
  useReRender();
  const route = useRoute();
  const {
    searchParams,
    parameters: [region, name, matchId, tab, tabId],
  } = route;

  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const [customComponent, setCustomComponent] = useState(null);

  const [gameName, tagLine] = separateDerivedRiotID(name);
  const derivedId = getDerivedId(region, name);
  const profile = state.lol.profiles[derivedId] || {};

  const isOwnProfile = isLinkedSummoner(state, region, name);
  const localMatchList = state.lol.localMatchlists?.[derivedId] || [];
  const userMatchlists = state.lol.matchlists?.[derivedId] || [];
  const matchlist = unionBy(localMatchList, userMatchlists, "id");
  const matchKey = `${region?.toUpperCase()}_${matchId}`;
  const matchData = state.lol.matches[matchKey];
  const timeline = state?.lol?.matchTimeline?.[matchId];
  const champions = getStaticData("champions");

  const proChampionId = searchParams.get("championId");

  const {
    currParticipant = {},
    queue,
    duration,
    isWinner,
    isRemake,
    // myChampion,
    myChampionIcon,
    // playerRole,
  } = useGetPostmatchData({
    matchData,
    proChampionId,
    profile: {
      riotAccount: {
        gameName,
        tagLine,
      },
    },
  });

  const benchmarkTier =
    (profile?.latestRanks || []).find((queue) => {
      return queue.queue === QUEUE_SYMBOLS.rankedSoloDuo;
    })?.tier || DEFAULT_COMPARISON_RANK;

  const player = {
    ...currParticipant,
    puuid: profile?.puuid,
    rank: benchmarkTier,
  };

  const creation = matchData ? matchData?.gameCreation : "—";

  const queueSymbol = QueueSymbol(queue || matchData?.gameMode);
  const isArena = isArenaQueue(queueSymbol);
  const isSwarm = isSwarmQueue(queueSymbol);
  const myTeamId = currParticipant?.teamId;
  const queueType = getTranslatedQueueName(t, queue);
  const isScorable = SCORABLE_QUEUE_TYPES.includes(queue);
  const playersRankings = isScorable ? PlayerScores(matchData) : null;

  const playerPlacement =
    playersRankings?.findIndex((p) => isSameAccount(p, currParticipant)) + 1;
  const isMVP = playerPlacement === 1;
  const isSupport =
    mapRoleToSymbol(currParticipant.teamPosition) === ROLE_SYMBOLS.support;

  const hasCustomComponent = CUSTOM_QUEUE_COMPONENTS[queueSymbol];
  useEffect(() => {
    (async () => {
      if (!CUSTOM_QUEUE_COMPONENTS[queueSymbol]) return;
      const component = CUSTOM_QUEUE_COMPONENTS[queueSymbol]();
      setCustomComponent(await component);
    })();
  }, [queueSymbol]);

  const tags = useGetTagsData({
    match: matchData,
    isRemake,
    account: profile,
    timeline,
    champions,
    isMVP,
  });

  const matchContext = useMemo(
    () => ({
      tab,
      tabId,
      isOwnProfile,
      match: matchData,
    }),
    [isOwnProfile, matchData, tab, tabId],
  );

  const lp =
    isRankedQueue(queue) && matchlist.length
      ? matchlist.find((match) => match.id === matchKey)?.playerMatch
          ?.playerMatchStats?.deltaLp
      : null;

  const MatchLayout = lolRefs.PostMatchLayout ?? SharedMatchLayout;

  const title = !matchData
    ? t("common:loading", "Loading...")
    : isWinner
      ? t("lol:postmatch.victory", "Victory")
      : myTeamId
        ? t("lol:postmatch.defeat", "Defeat")
        : isRemake
          ? t("lol:results.remake", "Remake")
          : t("common:cannotFindPlayer", "Cannot Find Player");

  return (
    <>
      {lolRefs.PostMatchDevSection ? (
        <lolRefs.PostMatchDevSection localPlayer={player} match={matchData} />
      ) : null}
      <MatchContextProvider value={matchContext}>
        <MatchLayout
          hideHeader={isSwarm}
          match={matchData}
          title={title}
          image={myChampionIcon}
          borderColor={
            !matchData
              ? "var(--shade3)"
              : isWinner
                ? "var(--turq)"
                : "var(--red)"
          }
          imageLink={`/lol/profile/${region}/${name}`}
          badges={tags}
          underTitle={
            <>
              {lp !== null ? (
                <span style={{ color: getLPColor(lp) }}>
                  {getLPString(lp, t)}
                </span>
              ) : null}
              <span>{queueType}</span>
              <span>{duration}</span>
              <TimeAgo date={creation} />
            </>
          }
        >
          <MainColumnsContainer>
            {customComponent ? (
              React.createElement(customComponent.default, {
                name,
                currParticipant,
                matchData,
                timelineData: timeline,
                sharable: true,
                className: "span-all",
              })
            ) : isArena ? (
              <div className="span-all">
                <MatchTabs
                  region={region}
                  matchId={matchId}
                  localPlayer={currParticipant}
                  profile={profile}
                  playerScores={playersRankings}
                />
              </div>
            ) : !hasCustomComponent ? (
              <>
                {lolRefs.PostMatchCoachingSection &&
                isSRCoachableQueue(matchData) ? (
                  <div className="span-all">
                    <lolRefs.PostMatchCoachingSection
                      localPlayer={player}
                      match={matchData}
                      timeline={timeline}
                    />
                  </div>
                ) : isSRCoachableQueue(matchData) || isARAMQueue(queue) ? (
                  <MatchPerformance matchKey={matchKey} player={player} />
                ) : null}
                {matchRefs.InContentBanner && (
                  <matchRefs.InContentBanner center={true} />
                )}
                {isSupport && isSRCoachableQueue(matchData) && (
                  <div className="span-all">
                    <SupportTimer
                      timeline={timeline}
                      currParticipant={currParticipant}
                      rank={benchmarkTier}
                    />
                  </div>
                )}
                {/* <div className="span-1">
                {playerRole === ROLE_SYMBOLS.jungle ? (
                  <Suspense>
                    <lolRefs.PostMatchJunglePathing
                      data={matchData?.junglePathData}
                      champion={myChampion?.key}
                      team={myTeamId}
                      championImage={myChampionIcon}
                    />
                  </Suspense>
                ) : null}
              </div> */}
                <div className="span-all">
                  <MatchTabs
                    region={region}
                    matchId={matchId}
                    localPlayer={currParticipant}
                    profile={profile}
                    playerScores={playersRankings}
                  />
                </div>
              </>
            ) : null}
          </MainColumnsContainer>
        </MatchLayout>
      </MatchContextProvider>
    </>
  );
}

export function meta(info) {
  const [region, userName, matchId] = info;
  const encodedName = encodeURIComponent(userName);
  const gqlRegion = REGION_LIST.find((obj) => {
    return obj.key === region;
  })?.gql;

  return {
    title: [
      "lol:meta.postmatch.title",
      "{{userName}}'s League of Legends Match Performance",
      { userName: userName },
    ],
    description: [
      "lol:meta.postmatch.description",
      "View {{userName}}'s League of Legends match history and see how they perform.",
      { userName: userName },
    ],

    image: {
      url: `${appURLs.BLITZ}/lol/match/${gqlRegion}/${encodedName}/${matchId}/preview.png`,
      alt: [
        "lol:meta.postmatch.title",
        "{{userName}}'s League of Legends Match Performance",
        { userName: userName },
      ],
      width: 800,
      height: 400,
    },
  };
}

export default PostMatch;
