import { css } from "goober";

export const containerCSS = () => css`
  display: flex;
  align-items: center;
  gap: var(--sp-1);
  color: var(--shade2);

  @container match-tabs (max-inline-size: 650px) {
    & {
      display: none;
    }
  }

  .team-bans {
    display: flex;
    gap: var(--sp-0_5);
  }
  .team-ban,
  .team-ban img {
    --radii: var(--br-sm);
    inline-size: var(--sp-4);
    block-size: var(--sp-4);
    border-radius: var(--radii);
  }
  .team-ban {
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      box-shadow: inset 0 0 0 1px var(--shade9);
      border-radius: var(--radii);
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      scale: 1.1;
    }
  }
`;
