import React, {
  // createRef,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Card } from "clutch/src/Card/Card";
import { Select } from "clutch/src/Select/Select";

import { readState } from "@/__main__/app-state.mjs";
import router, { updateRoute } from "@/__main__/router.mjs";
import RunesStatsCard from "@/game-lol/components/RunesStatsCard.jsx";
import { DEFAULT_COMPARISON_RANK } from "@/game-lol/constants/coaching-constants.mjs";
import LolColors from "@/game-lol/constants/colors.mjs";
import {
  QUEUE_SYMBOLS,
  RANK_SYMBOL_TO_STR,
  RANK_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import {
  getPreferredPostmatchComparisonRank,
  updatePreferredPostmatchComparisonRank,
} from "@/game-lol/utils/actions.mjs";
// import { getChampionSubClass } from "@/game-lol/utils/champion-utils.mjs";
import { coachingPerformance } from "@/game-lol/utils/coaching-utils.mjs";
import getRankIcon from "@/game-lol/utils/get-rank-icon.mjs";
// import getRoleIcon from "@/game-lol/utils/get-role-icon.mjs";
import Static from "@/game-lol/utils/static.mjs";
import QueueSymbol from "@/game-lol/utils/symbol-queue.mjs";
import {
  coachingStatsKey,
  coachingStatsVariables,
  getStaticData,
  isARAMQueue,
  mapRankToSymbol,
} from "@/game-lol/utils/util.mjs";
import { RankComparison } from "@/shared/Match.style.jsx";
import { OverlayMessage, PerfGrid } from "@/shared/Performance.style";
import PerformanceCard from "@/shared/PerformanceCard.jsx";
import PerformanceInputStats from "@/shared/PerformanceInputStats.jsx";
import { devLog } from "@/util/dev.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const BASE_CARD_HEIGHT = 390;

function MatchPerformanceCards({ matchKey, player }) {
  const { route } = router;
  const {
    lol: { championStats, championStatsByMin, matches },
    volatile: { lolCurrentSkinSelection },
  } = useSnapshot(readState);
  const [cardBgImg, setCardBgImg] = useState(null);
  const { t } = useTranslation();
  const cardRefs = useRef([]);
  const match = matches[matchKey];

  useEffect(() => {
    if (!player?.championId) return;

    if (lolCurrentSkinSelection?.selectedChampionId === player.championId) {
      setCardBgImg(
        Static.getChampionCenterSkinImage(
          player.championId,
          lolCurrentSkinSelection?.selectedSkinId,
        ),
      );
    } else {
      setCardBgImg(Static.getChampionCenterSkinImage(player.championId));
    }
  }, [player?.championId, lolCurrentSkinSelection]);

  const matchQueueSymbol = QueueSymbol(match.queueId);

  const tiers = Object.getOwnPropertySymbols(RANK_SYMBOL_TO_STR)
    .filter(
      (key) =>
        !RANK_SYMBOL_TO_STR[key]?.hideFilter &&
        key !== RANK_SYMBOLS.platinumPlus,
    )
    .map((key) => {
      const tierData = RANK_SYMBOL_TO_STR[key];
      const Icon = getRankIcon(tierData.key);

      return {
        value: tierData.key,
        text: [tierData.t.name, tierData.t.fallback],
        icon: Icon && <Icon />,
      };
    });
  const preferredComparisonRank = getPreferredPostmatchComparisonRank();
  const [playerRankSymbol, setPlayerRankSymbol] = useState(
    preferredComparisonRank || player.rank || DEFAULT_COMPARISON_RANK,
  );
  const playerRankDetails = RANK_SYMBOL_TO_STR[playerRankSymbol];
  const playerRankName = t(
    playerRankDetails.t.name,
    playerRankDetails.t.fallback,
  );
  const playerRankColors = LolColors.ranks?.[playerRankDetails?.key];

  const champions = getStaticData("champions");
  const championKey = champions?.keys?.[player.championId];
  const championName = champions?.[championKey]?.name;

  const isARAM = isARAMQueue(matchQueueSymbol);

  const coachingVars = coachingStatsVariables({
    championId: player?.championId,
    queue: isARAM ? QUEUE_SYMBOLS.aram : QUEUE_SYMBOLS.rankedSoloDuo,
    tier: !isARAM && playerRankSymbol,
    role: !isARAM && player.teamPosition,
  });
  const benchmarkData = isARAM
    ? championStats?.[coachingStatsKey(coachingVars)]?.[0]
    : championStatsByMin?.[coachingStatsKey(coachingVars)]?.divisionStats?.[0];
  const { loading, tooShort, categories, runesStats } = coachingPerformance({
    player,
    match,
    benchmarkData,
    queue: matchQueueSymbol,
  });

  // For debugging purposes
  devLog("Postmatch Coaching", categories);

  // const RoleIcon =
  //   player.individualPosition && getRoleIcon(player.individualPosition);

  const comparison = coachingVars.tier ? (
    <RankComparison
      className="span-3"
      style={{ color: playerRankColors?.text }}
    >
      <span className="vs type-form--button">{t("common:vs", "vs")}</span>
      <Select
        selected={playerRankDetails.key}
        options={tiers}
        onChange={(v) => {
          const rankSymbol = mapRankToSymbol(v);
          setPlayerRankSymbol(rankSymbol);
          updatePreferredPostmatchComparisonRank(rankSymbol);
          updateRoute(route.currentPath, route.searchParams, {
            comparisonRank: v,
          });
        }}
      />
      {championName && (
        <span className="type-form--button">{championName}</span>
      )}
    </RankComparison>
  ) : null;

  const cols = categories.length + (runesStats ? 1 : 0);

  if (loading && !(benchmarkData instanceof Error)) {
    return (
      <>
        {!isARAM && comparison}
        <PerfGrid className="span-3" style={{ "--cols": 3 }}>
          {categories.map((category) => {
            return (
              <Card
                loading
                key={category.title}
                style={{ height: BASE_CARD_HEIGHT }}
              />
            );
          })}
          {runesStats && <Card loading style={{ height: BASE_CARD_HEIGHT }} />}
        </PerfGrid>
      </>
    );
  }

  if (benchmarkData instanceof Error) {
    return null;
  }

  if (tooShort) {
    return (
      <>
        {comparison}
        <PerfGrid className="span-3" style={{ "--cols": cols }}>
          {categories.map((category) => {
            return (
              <Card
                key={category.title}
                style={{ height: BASE_CARD_HEIGHT, opacity: 0.38 }}
              />
            );
          })}
          {runesStats && (
            <Card style={{ height: BASE_CARD_HEIGHT, opacity: 0.38 }} />
          )}
          <OverlayMessage>
            {t(
              "common:performance.matchTooShort",
              "Match too short to accurately analyze.",
            )}
          </OverlayMessage>
        </PerfGrid>
      </>
    );
  }

  return (
    <>
      {comparison}
      <PerfGrid className="span-3" style={{ "--cols": cols }}>
        {categories.map((category, i) => {
          return (
            <PerformanceCard
              key={category.title}
              ref={cardRefs.current[i]}
              title={category.title}
              score={category.performance.score}
              bgImg={{
                src: cardBgImg,
                onError: (ev) => {
                  if (!player?.championId) return;
                  const defaultImg = Static.getChampionCenterSkinImage(
                    player?.championId,
                  );
                  if (ev.target.src !== defaultImg) setCardBgImg(defaultImg);
                },
                className: bgImgStyle(),
              }}
              infoTooltip={t(...[category.explanation])}
              rankColor={playerRankColors?.fill || "var(--shade1)"}
              subContent={
                <PerformanceInputStats
                  rows={category.stats.map((stat) => {
                    const { score } = stat;
                    const tooltipDescription =
                      score.val < score.mean
                        ? [
                            "common:coaching.youDidAmountWorseThanAverageRankCharacter",
                            "You did {{amount}} worse than the average {{rank}} {{character}}",
                            {
                              amount: (
                                1 -
                                score.val / score.mean
                              ).toLocaleString(getLocale(), {
                                style: "percent",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }),
                              rank: playerRankName,
                              character: championName,
                            },
                          ]
                        : [
                            "common:coaching.youDidAmountBetterThanAverageRankCharacter",
                            "You did {{amount}} better than the average {{rank}} {{character}}",
                            {
                              amount: (
                                score.val / score.mean -
                                1
                              ).toLocaleString(getLocale(), {
                                style: "percent",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }),
                              rank: playerRankName,
                              character: championName,
                            },
                          ];

                    return {
                      label: Array.isArray(stat.title)
                        ? t(...stat.title)
                        : stat.title,
                      value: score.val,
                      format: stat.format,
                      fill: score.score,
                      min: score.min,
                      max: score.max,
                      mean: score.mean,
                      tooltipOptions: {
                        description: tooltipDescription,
                        min: {
                          label: [
                            "lol:rankNameBot25Percent",
                            "{{rankName}} Bot 25%",
                            {
                              rankName: playerRankName,
                            },
                          ],
                          labelColor: playerRankColors?.fill || "var(--shade1)",
                        },
                        max: {
                          label: [
                            "lol:rankNameTop25Percent",
                            "{{rankName}} Top 25%",
                            {
                              rankName: playerRankName,
                            },
                          ],
                          labelColor: playerRankColors?.fill || "var(--shade1)",
                        },
                        mean: {
                          label: [
                            "lol:rankNameAvg",
                            "{{rankName}} Avg",
                            {
                              rankName: playerRankName,
                            },
                          ],
                          labelColor: playerRankColors?.fill || "var(--shade1)",
                        },
                      },
                    };
                  })}
                />
              }
            />
          );
        })}
        {runesStats && (
          <RunesStatsCard player={player} maxHeight={BASE_CARD_HEIGHT} />
        )}
      </PerfGrid>
    </>
  );
}

export default memo(MatchPerformanceCards);

const bgImgStyle = () => css`
  & {
    position: absolute;
    top: 0;
    left: 50%;
    translate: -32% -13%;
    -webkit-mask-image: radial-gradient(
      circle at top center,
      hsl(0deg 0% 0% / 39%),
      transparent 80%
    );
    width: 200%;
  }
`;
