import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card";

import { formatDuration } from "@/app/util.mjs";
import { RANK_SYMBOLS, SUPPORT_ITEM } from "@/game-lol/constants/constants.mjs";
import BENCHMARK from "@/game-lol/constants/support-item-timer.mjs";
import normalize from "@/game-lol/utils/normalize.mjs";
import Static from "@/game-lol/utils/static.mjs";
import { getSuppItemUpgradeTimes } from "@/game-lol/utils/util.mjs";
import { PerformanceBars } from "@/shared/PerformanceBars.jsx";

function scoreColor(score) {
  return score >= 0.7
    ? "var(--green-hsl)"
    : score <= 0.2
      ? "var(--red-hsl)"
      : "var(--shade1-hsl)";
}

const SupportTimer = ({
  currParticipant,
  timeline,
  rank = RANK_SYMBOLS.gold,
}) => {
  const { t } = useTranslation();
  const suppStats = useMemo(() => {
    return getSuppItemUpgradeTimes(timeline, currParticipant);
  }, [timeline, currParticipant]);

  if (timeline instanceof Error) {
    return null;
  }

  if (suppStats.loading) {
    return <Card loading style={{ height: 160 }} />;
  }

  // lower is better
  const tier1Range = [
    BENCHMARK[rank][3865].mean + BENCHMARK[rank][3865].stdev,
    BENCHMARK[rank][3865].mean - BENCHMARK[rank][3865].stdev,
  ];
  const tier2Range = [
    BENCHMARK[rank][3866].mean + BENCHMARK[rank][3866].stdev,
    BENCHMARK[rank][3866].mean - BENCHMARK[rank][3865].stdev,
  ];

  const tier1Score = normalize(
    suppStats.tier1Time,
    tier1Range[1],
    tier1Range[0],
  );
  const tier2Score = normalize(
    suppStats.tier2Time,
    tier2Range[1],
    tier2Range[0],
  );

  return (
    <Card title={t("lol:overviewStats.supportItemTimer", "Support Item Timer")}>
      <PerformanceBars
        rows={[
          {
            valueDisplay: formatDuration(suppStats.tier1Time, "mm:ss"),
            fill: tier1Score,
            label: ["", "Tier 1 Upgrade"],
            img: Static.getItemImage(SUPPORT_ITEM.two),
            colorHsl: scoreColor(tier1Score),
          },
          {
            valueDisplay: formatDuration(suppStats.tier2Time, "mm:ss"),
            fill: tier2Score,
            label: ["", "Tier 2 Upgrade"],
            img: Static.getItemImage(SUPPORT_ITEM.three),
            colorHsl: scoreColor(tier2Score),
          },
        ]}
      />
    </Card>
  );
};

export default SupportTimer;
