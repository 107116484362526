import React from "react";

import { readState } from "@/__main__/app-state.mjs";
import { isSamePlayer } from "@/game-lol/utils/match-utils.mjs";
import { getGoldDiff } from "@/game-lol/utils/util.mjs";
import IncomeCompare from "@/shared/IncomeCompare.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const GoldGraph = ({ region, matchId, currParticipant }) => {
  const state = useSnapshot(readState);

  const match = state.lol.matches[`${region?.toUpperCase()}_${matchId}`];
  const timeline = state?.lol?.matchTimeline?.[matchId];

  const team1 = match.participants.filter((player) => player.teamId === 100);
  const myTeam =
    match.participants.find((p) => {
      return isSamePlayer(p, currParticipant);
    })?.teamId || 100;

  const goldDiff = getGoldDiff(
    timeline.frames,
    team1.length,
    match.participants.length,
    myTeam,
  )
    .map((d) => {
      return { ...d, time: Math.floor(d.time / (60 * 1000)) };
    })
    .filter((d) => {
      // Ignore minute 0 and 1
      return d.time > 2;
    });

  return <IncomeCompare data={goldDiff} />;
};

export default GoldGraph;
