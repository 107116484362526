import React from "react";

import { readState } from "@/__main__/app-state.mjs";
import { calcWinProbability } from "@/game-lol/utils/calc-win-probability.mjs";
import { isSamePlayer } from "@/game-lol/utils/match-utils.mjs";
import { getGoldDiff } from "@/game-lol/utils/util.mjs";
import IncomeCompare from "@/shared/IncomeCompare.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const WinProbability = ({ region, matchId, currParticipant }) => {
  const {
    lol: { matches, matchTimeline },
  } = useSnapshot(readState);

  const match = matches[`${region?.toUpperCase()}_${matchId}`];
  const timeline = matchTimeline?.[matchId];

  const team1 = match.participants.filter((player) => player.teamId === 100);
  const myTeam =
    match.participants.find((p) => {
      return isSamePlayer(p, currParticipant);
    })?.teamId || 100;

  const winProbabilityDiff = getGoldDiff(
    timeline.frames,
    team1.length,
    match.participants.length,
    myTeam,
  )
    .map((d) => {
      const mins = Math.floor(d.time / (60 * 1000));
      const winProbability = calcWinProbability(mins, d.diff);
      return {
        ...d,
        time: mins,
        diff: winProbability - 0.5,
        diffDisplay: winProbability,
      };
    })
    .filter((d) => {
      // Ignore minute 0 and 1
      return d.time > 2;
    });

  return (
    <IncomeCompare
      data={winProbabilityDiff}
      format={{
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }}
      tooltipLabels={{
        ahead: ["common:winProbabilityPercent", "Win Probability: {{amount}}"],
        behind: ["common:winProbabilityPercent", "Win Probability: {{amount}}"],
        even: ["common:winProbabilityPercent", "Win Probability: {{amount}}"],
      }}
    />
  );
};

export default WinProbability;
