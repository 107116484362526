import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { css, styled } from "goober";
import { Card } from "clutch/src/Card/Card";

import { formatDuration } from "@/app/util.mjs";
import MatchTimeline from "@/game-lol/components/MatchTimeline.jsx";
import { MAP_ID } from "@/game-lol/constants/constants.mjs";

const cssTextImg = () => css`
  display: inline-block;
  margin-bottom: calc(var(--sp-1) * -1);
  margin-left: var(--sp-1);
  font-size: var(--sp-5);
  color: var(--shade2);
`;

const TimelineItemTitle = styled("p")`
  color: var(--shade1);
`;

const TimelineItemBar = styled("div")`
  margin-right: var(--sp-2);
  margin-top: var(--sp-1);
  align-items: center;
  display: flex;
  flex-direction: column;

  .dot {
    width: var(--sp-2);
    height: var(--sp-2);
    background: hsla(var(--match-color) / 1);
    border: 2px solid var(--shade7);
    border-radius: 50%;
  }
  .bar {
    width: var(--sp-0_5);
    min-height: 50px;
    flex: 1;
    background: hsla(var(--match-color) / 0.15);
  }
`;

const ScrollingTimeline = styled("div")`
  height: 28rem;
  padding: var(--sp-3);
  overflow: auto;

  &::-webkit-scrollbar-thumb {
    background-color: var(--shade6);
    border: 4px solid var(--card-surface);
  }

  svg {
    width: var(--sp-5);
    height: var(--sp-5);
  }
`;

// HACK: Riot events have Howling Abyss turrets switched around :(
// It seems to be based on player side ?
// Might need to make these coords a bit more fuzzy
/*
Left or Bottom
{x: 4943, y: 4929}, // "OUTER_TURRET"
{x: 3827, y: 3841}, // "BASE_TURRET"
{x: 3099, y: 3196}, // "INHIBITOR_BUILDING"
{x: 2506, y: 2110}, // "NEXUS_TURRET"
{x: 2061, y: 2575}, // "NEXUS_TURRET"

Right or Top
{x: 7885, y: 7773}, // "OUTER_TURRET"
{x: 9002, y: 8850}, // "BASE_TURRET"
{x: 9696, y: 9544}, // "INHIBITOR_BUILDING"
{x: 10337, y: 10574}, // "NEXUS_TURRET"
{x: 10784, y: 10109}, // "NEXUS_TURRET"
*/
function reMapHowlingAbyssTurrets(item) {
  const {
    position: { x },
  } = item;
  const shallowClone = { ...item };
  if (x === 4943 || x === 7885) shallowClone.towerType = "OUTER_TURRET";
  else if (x === 3827 || x === 9002) shallowClone.towerType = "BASE_TURRET";
  else if (x === 2506 || x === 10337) shallowClone.towerType = "NEXUS_TURRET";
  else if (x === 2061 || x === 10784) shallowClone.towerType = "NEXUS_TURRET";

  return shallowClone;
}

const TimelineItem = ({ item, match, currParticipant, t }) => {
  const { participants } = match;
  const isKiller = item.killerId === currParticipant.participantId;
  const isVictim = item.victimId === currParticipant.participantId;

  if (
    match.mapId === MAP_ID.HOWLING_ABYSS &&
    item.type === "BUILDING_KILL" &&
    item.buildingType === "TOWER_BUILDING"
  ) {
    item = reMapHowlingAbyssTurrets(item);
  }

  const content = MatchTimeline({
    t,
    item,
    isKiller,
    isVictim,
    playerChampionID: currParticipant.championId,
    playerChampionKey: currParticipant.championName,
    participants,
    textImg: cssTextImg(),
  });

  if (!content) return null;

  return (
    <div className="flex">
      <TimelineItemBar
        style={{
          "--match-color": isVictim ? "var(--red-hsl)" : "var(--blue-hsl)",
        }}
      >
        <div className="dot" />
        <div className="bar" />
      </TimelineItemBar>
      <div>
        <TimelineItemTitle className="type-caption--bold">
          {t("common:minutes", "{{minutes}} mins", {
            minutes: (formatDuration(item.timestamp, "ss") / 60).toFixed(0),
          })}
        </TimelineItemTitle>
        {content}
      </div>
    </div>
  );
};

const PostMatchTimeline = ({ timeline, match, currParticipant }) => {
  const { t } = useTranslation();
  if (!timeline || !currParticipant) return null;

  const eventsData = (timeline.frames || []).reduce((allEvents, frame) => {
    const { events } = frame;
    const yourEvents = events.filter((event) => {
      return (
        (event.type === "CHAMPION_KILL" &&
          [
            event.killerId,
            event.victimId,
            ...(event.assistingParticipantIds || []),
          ].includes(currParticipant.participantId)) ||
        (event.type === "BUILDING_KILL" &&
          [event.killerId, ...(event.assistingParticipantIds || [])].includes(
            currParticipant.participantId,
          )) ||
        (event.type === "ELITE_MONSTER_KILL" &&
          [event.killerId].includes(currParticipant.participantId)) ||
        (event.type === "WARD_PLACED" &&
          [event.creatorId].includes(currParticipant.participantId) &&
          ["YELLOW_TRINKET", "CONTROL_WARD"].includes(event.wardType))
      );
    });
    return [...allEvents, ...yourEvents];
  }, []);

  return (
    <Card padding="0">
      <ScrollingTimeline>
        {eventsData.map((item, i) => {
          return (
            <div
              key={`${currParticipant?.puuid}-${i}` || i}
              style={{ paddingRight: "var(--sp-2)" }}
            >
              <TimelineItem
                item={item}
                currParticipant={currParticipant}
                match={match}
                t={t}
              />
            </div>
          );
        })}
      </ScrollingTimeline>
    </Card>
  );
};

export default withTranslation(["lol"])(PostMatchTimeline);
