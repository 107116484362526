import { css } from "goober";

export const containerCSS = () => css`
  container-type: inline-size;
  container-name: match-tabs;
  border-radius: var(--br-lg);
  overflow: hidden;

  .tabs-header {
    display: flex;
    justify-content: space-between;
    padding-inline: var(--sp-2);
    background: var(--shade8);
    border-bottom: 1px solid var(--shade6);
  }
  .tabs-list {
    display: flex;

    @container match-tabs (max-inline-size: 300px) {
      & {
        flex-direction: column;
        width: 100%;
      }
    }
  }
  .tabs-tab {
    position: relative;
    display: flex;
    align-items: center;
    padding: var(--sp-4) var(--sp-2);
    color: var(--shade2);
    background: transparent;
    cursor: pointer;

    &::before {
      view-transition-name: match-tab-indicator;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: var(--sp-6);
      height: 3px;
      background: var(--primary);
      border-top-left-radius: var(--br-sm);
      border-top-right-radius: var(--br-sm);
      translate: -50% 0;
    }

    &.active {
      color: var(--shade0);

      &::before {
        content: "";
      }
    }

    &[data-new] {
      &::after {
        content: attr(data-new);
        background: var(--blitz-loading-stroke);
        color: white;
        padding: 0 var(--sp-1);
        margin-inline-start: var(--sp-1_5);
        font-size: 0.75rem;
        font-weight: 600;
        text-transform: uppercase;
        border-radius: var(--br-sm);
      }
    }

    @container match-tabs (max-inline-size: 300px) {
      & {
        padding: var(--sp-2);
      }
    }
  }

  .tabs-content {
    padding: var(--sp-2);
  }
`;
