import { RANK_SYMBOLS } from "@/game-lol/constants/constants-ranks.mjs";

// Data provided by data team
const DATA = [
  {
    itemId: 3866,
    queue: 420,
    tier: RANK_SYMBOLS.bronze,
    matches: 165011,
    mean: 840712.2016379996,
    stdev: 95199.1351103812,
  },
  {
    itemId: 3866,
    queue: 420,
    tier: RANK_SYMBOLS.platinum,
    matches: 190930,
    mean: 825072.5964813599,
    stdev: 82610.07287775645,
  },
  {
    itemId: 3866,
    queue: 420,
    tier: RANK_SYMBOLS.gold,
    matches: 176754,
    mean: 828329.6122496062,
    stdev: 85359.03043079376,
  },
  {
    itemId: 3866,
    queue: 420,
    tier: RANK_SYMBOLS.grandmaster,
    matches: 3079,
    mean: 816421.5595238095,
    stdev: 90194.10918707254,
  },
  {
    itemId: 3866,
    queue: 420,
    tier: RANK_SYMBOLS.challenger,
    matches: 936,
    mean: 813183.4803312629,
    stdev: 102839.80273314333,
  },
  {
    itemId: 3866,
    queue: 420,
    tier: RANK_SYMBOLS.master,
    matches: 19657,
    mean: 819178.1869475173,
    stdev: 87809.63818522601,
  },
  {
    itemId: 3866,
    queue: 420,
    tier: RANK_SYMBOLS.diamond,
    matches: 56446,
    mean: 821422.3858618202,
    stdev: 83749.19375953666,
  },
  {
    itemId: 3866,
    queue: 420,
    tier: RANK_SYMBOLS.emerald,
    matches: 146661,
    mean: 821902.33643745,
    stdev: 82691.53918007079,
  },
  {
    itemId: 3866,
    queue: 420,
    tier: RANK_SYMBOLS.iron,
    matches: 67215,
    mean: 860635.8830520279,
    stdev: 111919.84123554545,
  },
  {
    itemId: 3866,
    queue: 420,
    tier: RANK_SYMBOLS.silver,
    matches: 172377,
    mean: 833174.6117934416,
    stdev: 88054.56648754541,
  },
  {
    itemId: 3865,
    queue: 420,
    tier: RANK_SYMBOLS.emerald,
    matches: 146740,
    mean: 495722.60175002716,
    stdev: 68130.57128113408,
  },
  {
    itemId: 3865,
    queue: 420,
    tier: RANK_SYMBOLS.silver,
    matches: 172526,
    mean: 500368.546714582,
    stdev: 68965.72452423988,
  },
  {
    itemId: 3865,
    queue: 420,
    tier: RANK_SYMBOLS.diamond,
    matches: 56654,
    mean: 495780.8894452937,
    stdev: 69826.8999659569,
  },
  {
    itemId: 3865,
    queue: 420,
    tier: RANK_SYMBOLS.grandmaster,
    matches: 3115,
    mean: 497663.698245614,
    stdev: 80554.49168017163,
  },
  {
    itemId: 3865,
    queue: 420,
    tier: RANK_SYMBOLS.bronze,
    matches: 165464,
    mean: 504604.67817774403,
    stdev: 74860.05977574657,
  },
  {
    itemId: 3865,
    queue: 420,
    tier: RANK_SYMBOLS.gold,
    matches: 176869,
    mean: 498378.5779494461,
    stdev: 68511.71375866864,
  },
  {
    itemId: 3865,
    queue: 420,
    tier: RANK_SYMBOLS.platinum,
    matches: 190932,
    mean: 496722.30593030597,
    stdev: 66023.28132030317,
  },
  {
    itemId: 3865,
    queue: 420,
    tier: RANK_SYMBOLS.iron,
    matches: 67846,
    mean: 516029.70697407867,
    stdev: 89177.48202194955,
  },
  {
    itemId: 3865,
    queue: 420,
    tier: RANK_SYMBOLS.challenger,
    matches: 933,
    mean: 497115.1927966102,
    stdev: 83878.65114688923,
  },
  {
    itemId: 3865,
    queue: 420,
    tier: RANK_SYMBOLS.master,
    matches: 19762,
    mean: 495542.20105660375,
    stdev: 75445.99009534817,
  },
  {
    itemId: 3866,
    queue: 440,
    tier: RANK_SYMBOLS.bronze,
    matches: 34591,
    mean: 846146.4881044849,
    stdev: 95796.47061266203,
  },
  {
    itemId: 3866,
    queue: 440,
    tier: RANK_SYMBOLS.grandmaster,
    matches: 267,
    mean: 823728.7259259259,
    stdev: 89041.14730169378,
  },
  {
    itemId: 3866,
    queue: 440,
    tier: RANK_SYMBOLS.iron,
    matches: 13524,
    mean: 862017.7241810821,
    stdev: 108058.29522804121,
  },
  {
    itemId: 3866,
    queue: 440,
    tier: RANK_SYMBOLS.master,
    matches: 1112,
    mean: 816765.2294642857,
    stdev: 84724.27841042986,
  },
  {
    itemId: 3866,
    queue: 440,
    tier: RANK_SYMBOLS.gold,
    matches: 35292,
    mean: 833365.1471193125,
    stdev: 89075.4434656612,
  },
  {
    itemId: 3866,
    queue: 440,
    tier: RANK_SYMBOLS.platinum,
    matches: 38278,
    mean: 829357.8433497536,
    stdev: 87217.02528686349,
  },
  {
    itemId: 3866,
    queue: 440,
    tier: RANK_SYMBOLS.emerald,
    matches: 41190,
    mean: 823143.9009865255,
    stdev: 86082.97097230188,
  },
  {
    itemId: 3866,
    queue: 440,
    tier: RANK_SYMBOLS.silver,
    matches: 36036,
    mean: 836723.2118361153,
    stdev: 90851.51036089372,
  },
  {
    itemId: 3866,
    queue: 440,
    tier: RANK_SYMBOLS.challenger,
    matches: 105,
    mean: 830395.6571428571,
    stdev: 82833.52377658882,
  },
  {
    itemId: 3866,
    queue: 440,
    tier: RANK_SYMBOLS.diamond,
    matches: 13316,
    mean: 820922.8077781083,
    stdev: 89934.77389689669,
  },
  {
    itemId: 3865,
    queue: 440,
    tier: RANK_SYMBOLS.emerald,
    matches: 41107,
    mean: 498311.14974840335,
    stdev: 74398.28370911168,
  },
  {
    itemId: 3865,
    queue: 440,
    tier: RANK_SYMBOLS.iron,
    matches: 13604,
    mean: 517670.9756008206,
    stdev: 82817.39056562149,
  },
  {
    itemId: 3865,
    queue: 440,
    tier: RANK_SYMBOLS.bronze,
    matches: 34680,
    mean: 508707.4985057471,
    stdev: 74085.88757291803,
  },
  {
    itemId: 3865,
    queue: 440,
    tier: RANK_SYMBOLS.platinum,
    matches: 38264,
    mean: 500901.9124941507,
    stdev: 73670.80724933297,
  },
  {
    itemId: 3865,
    queue: 440,
    tier: RANK_SYMBOLS.master,
    matches: 1102,
    mean: 494504.92773261067,
    stdev: 76867.51489708826,
  },
  {
    itemId: 3865,
    queue: 440,
    tier: RANK_SYMBOLS.grandmaster,
    matches: 268,
    mean: 499178.75925925927,
    stdev: 65395.78596291681,
  },
  {
    itemId: 3865,
    queue: 440,
    tier: RANK_SYMBOLS.challenger,
    matches: 104,
    mean: 501138.6153846154,
    stdev: 56259.37942742857,
  },
  {
    itemId: 3865,
    queue: 440,
    tier: RANK_SYMBOLS.silver,
    matches: 36047,
    mean: 503875.233140868,
    stdev: 71707.69482608282,
  },
  {
    itemId: 3865,
    queue: 440,
    tier: RANK_SYMBOLS.gold,
    matches: 35304,
    mean: 502412.2905953992,
    stdev: 70862.88090887127,
  },
  {
    itemId: 3865,
    queue: 440,
    tier: RANK_SYMBOLS.diamond,
    matches: 13222,
    mean: 498047.8258258258,
    stdev: 80097.11399719109,
  },
];

// Combine data to be more useful
export default DATA.reduce((acc, curr) => {
  const { itemId, tier, matches, mean, stdev } = curr;
  if (!tier) return acc;
  if (!acc[tier]) acc[tier] = {};
  if (!acc[tier][itemId]) acc[tier][itemId] = { matches: 0, mean: 0, stdev: 0 };
  acc[tier][itemId].matches += matches;

  if (acc[tier][itemId].mean) {
    acc[tier][itemId].mean = (acc[tier][itemId].mean + mean) / 2;
  } else {
    acc[tier][itemId].mean = mean;
  }

  if (acc[tier][itemId].stdev) {
    acc[tier][itemId].stdev = (acc[tier][itemId].stdev + stdev) / 2;
  } else {
    acc[tier][itemId].stdev = stdev;
  }

  return acc;
}, {});
